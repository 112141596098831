import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>VibeCheck: Discover and Quantify Qualitative Differences in LLMs</title>
      </Helmet>
      
      {/* Header */}
      <header className="bg-white shadow-md py-6">
        <div className="container mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-bold text-center">
            VibeCheck: Discover and Quantify Qualitative Differences in Large Language Models
          </h1>
          <div className="mt-4 text-center">
            <p className="text-lg text-blue-600">
              <span className="mx-2 hover:underline"><a href="https://github.com" target="_blank" rel="noopener noreferrer">Lisa Dunlap</a></span>•
              <span className="mx-2 hover:underline"><a href="https://github.com" target="_blank" rel="noopener noreferrer">Krishna Mandal</a></span>•
              <span className="mx-2 hover:underline"><a href="https://github.com" target="_blank" rel="noopener noreferrer">Trevor Darrell</a></span>•
              <span className="mx-2 hover:underline"><a href="https://github.com" target="_blank" rel="noopener noreferrer">Jacob Steinhardt</a></span>•
              <span className="mx-2 hover:underline"><a href="https://github.com" target="_blank" rel="noopener noreferrer">Joseph E Gonzalez</a></span>
            </p>
          </div>
        </div>
      </header>
      
      {/* Main content */}
      <main className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left sidebar with links */}
          <div className="lg:col-span-1">
            <div className="bg-white p-6 rounded-lg shadow-md mb-6">
              <h2 className="text-xl font-semibold mb-4">Resources</h2>
              <ul className="space-y-3">
                <li>
                  <Link to="/visualizer" className="flex items-center text-blue-600 hover:underline">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                    </svg>
                    Vibe Visualizer Tool
                  </Link>
                </li>
                <li>
                  <a href="https://example.com/paper.pdf" className="flex items-center text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                    </svg>
                    Paper (PDF)
                  </a>
                </li>
                <li>
                  <a href="https://github.com/example/vibecheck" className="flex items-center text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                    Source Code
                  </a>
                </li>
                <li>
                  <a href="https://huggingface.co/datasets/example/vibecheck" className="flex items-center text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
                    </svg>
                    Dataset
                  </a>
                </li>
              </ul>
            </div>
          </div>
          
          
          {/* Main content area */}
          <div className="lg:col-span-2">
            {/* Abstract section */}
            <section className="bg-white p-6 rounded-lg shadow-md mb-8">
              <h2 className="text-2xl font-bold mb-4">Abstract</h2>
              <div className="prose max-w-none">
                <p>
                  Large language models (LLMs) often exhibit subtle yet distinctive characteristics in their outputs that users intuitively recognize, but struggle to quantify. These "vibes" -- such as tone, formatting, or writing style -- influence user preferences, yet traditional evaluations focus primarily on the singular axis of correctness. We introduce VibeCheck, a system for automatically comparing a pair of LLMs by discovering identifying traits of a model (vibes) that are well-defined, differentiating, and user-aligned.
                </p>
                <p>
                  VibeCheck iteratively discovers vibes from model outputs and then utilizes a panel of LLM judges to quantitatively measure the utility of each vibe. We validate that the vibes generated by VibeCheck align with those found in human discovery and run VibeCheck on pairwise preference data from real-world user conversations with Llama-3-70b vs GPT-4. VibeCheck reveals that Llama has a friendly, funny, and somewhat controversial vibe. These vibes predict model identity with 80% accuracy and human preference with 61% accuracy.
                </p>
                <p>
                  Lastly, we run VibeCheck on a variety of models and tasks including summarization, math, and captioning to provide insight into differences in model behavior. VibeCheck discovers vibes like Command X prefers to add concrete intros and conclusions when summarizing in comparison to TNGL, Llama-405b often overexplains its thought process on math problems compared to GPT-4o, and GPT-4 prefers to focus on the mood and emotions of the scene when captioning compared to Gemini-1.5-Flash.
                </p>
              </div>
            </section>
            
            {/* Methods figure section */}
            <section className="bg-white p-6 rounded-lg shadow-md mb-8">
              <h2 className="text-2xl font-bold mb-4">Method</h2>
              <div className="flex items-center justify-center">
                <img
                  src="/method.jpeg"
                  alt="VibeCheck Method Diagram"
                  className="w-full h-auto"
                />
              </div>
              <div className="mt-4 prose max-w-none">
                <p>
                  The VibeCheck system operates in three main phases: (1) vibe discovery, where we automatically identify candidate vibe descriptions that differentiate LLM outputs; (2) vibe validation, where we filter and refine vibes to ensure they are well-defined, distinctive, and user-aligned; and (3) vibe analysis, where we use the discovered vibes to analyze model outputs and predict user preferences.
                </p>
              </div>
            </section>
            
            {/* Key findings section */}
            <section className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4">Key Findings</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="border border-gray-200 rounded-lg p-4">
                  <h3 className="text-lg font-semibold mb-2">Vibe Discovery</h3>
                  <p>VibeCheck automatically discovers meaningful qualitative differences between LLMs that align with human intuition.</p>
                </div>
                <div className="border border-gray-200 rounded-lg p-4">
                  <h3 className="text-lg font-semibold mb-2">Predictive Power</h3>
                  <p>Discovered vibes predict model identity with 80% accuracy and human preferences with 61% accuracy.</p>
                </div>
                <div className="border border-gray-200 rounded-lg p-4">
                  <h3 className="text-lg font-semibold mb-2">Cross-Task Analysis</h3>
                  <p>Different models show distinct vibes across summarization, math problem-solving, and image captioning tasks.</p>
                </div>
                <div className="border border-gray-200 rounded-lg p-4">
                  <h3 className="text-lg font-semibold mb-2">Interactive Exploration</h3>
                  <p>Explore model vibes and see example outputs with our <Link to="/visualizer" className="text-blue-600 hover:underline">interactive visualizer</Link>.</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      
      {/* Citation section moved here, before footer */}
      <div className="container mx-auto px-4 py-6">
        <div className="bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto">
          <h2 className="text-xl font-semibold mb-4">Citation</h2>
          <div className="bg-gray-100 p-3 rounded-md font-mono text-sm overflow-x-auto">
            <pre>
{`@article{dunlap2023vibecheck,
  title={VibeCheck: Discover and Quantify Qualitative Differences in Large Language Models},
  author={Dunlap, Lisa and Mandal, Krishna and Darrell, Trevor and Steinhardt, Jacob and Gonzalez, Joseph E},
  journal={arXiv preprint},
  year={2023}
}`}
            </pre>
          </div>
        </div>
      </div>
      
      {/* Footer */}
      <footer className="bg-white border-t mt-12 py-6">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {new Date().getFullYear()} VibeCheck Team. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage; 