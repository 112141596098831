import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

const VibeTable = ({ 
  vibes, 
  sortColumn, 
  sortDirection, 
  handleSort, 
  expandedVibes, 
  toggleVibeExpansion,
  hideVibesWithLowSeparability,
  toggleHideVibesWithLowSeparability
}) => {
  const displayedVibes = hideVibesWithLowSeparability 
    ? vibes.filter(vibe => Math.abs(vibe.score) >= 0.05)
    : vibes;

  return (
    <div className="mb-8">
      <h2 className="text-xl font-bold mb-4">Vibe Metrics Comparison</h2>
      <div className="bg-white p-4 rounded shadow">
        <div className="flex justify-end mb-4">
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={hideVibesWithLowSeparability}
              onChange={toggleHideVibesWithLowSeparability}
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            <span className="ml-3 text-sm font-medium text-gray-700">Hide vibes with low separability (|score| &lt; 0.05)</span>
          </label>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b-2 text-left cursor-pointer w-1/2" onClick={() => handleSort('vibe')}>
                  Vibe {sortColumn === 'vibe' && (sortDirection === 'asc' ? '▲' : '▼')}
                  <span className="ml-2 text-blue-500 text-xs font-normal">(click to expand)</span>
                </th>
                <th className="py-2 px-4 border-b-2 text-right cursor-pointer" onClick={() => handleSort('score')}>
                  Model ID Score {sortColumn === 'score' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="py-2 px-4 border-b-2 text-right cursor-pointer" onClick={() => handleSort('pref_score')}>
                  Preference Score {sortColumn === 'pref_score' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="py-2 px-4 border-b-2 text-right cursor-pointer" onClick={() => handleSort('coef_modelID')}>
                  Model ID Coefficient {sortColumn === 'coef_modelID' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="py-2 px-4 border-b-2 text-right cursor-pointer" onClick={() => handleSort('coef_preference')}>
                  Preference Coefficient {sortColumn === 'coef_preference' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
              </tr>
            </thead>
            <tbody>
              {displayedVibes.map((vibe, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="py-2 px-4 border-b w-1/2" title={vibe.vibe}>
                    <div 
                      className={`cursor-pointer transition-all duration-200`}
                      onClick={() => toggleVibeExpansion(index)}
                    >
                      <div className={`markdown-content prose prose-sm max-w-none ${expandedVibes[index] ? '' : 'line-clamp-1'}`}>
                        <ReactMarkdown 
                          remarkPlugins={[remarkMath]} 
                          rehypePlugins={[rehypeKatex]}
                        >
                          {vibe.vibe}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b text-right">
                    <span className={
                      vibe.score < 0 ? 'text-red-600' : 
                      vibe.score > 0 ? 'text-green-600' : 
                      'text-gray-600'
                    }>
                      {vibe.score.toFixed(2)}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-right">
                    <span className={
                      vibe.pref_score < 0 ? 'text-red-600' : 
                      vibe.pref_score > 0 ? 'text-green-600' : 
                      'text-gray-600'
                    }>
                      {vibe.pref_score.toFixed(2)}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-right">
                    <span className={
                      vibe.coef_modelID < 0 ? 'text-red-600' : 
                      vibe.coef_modelID > 0 ? 'text-green-600' : 
                      'text-gray-600'
                    }>
                      {vibe.coef_modelID.toFixed(2)}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-right">
                    <span className={
                      vibe.coef_preference < 0 ? 'text-red-600' : 
                      vibe.coef_preference > 0 ? 'text-green-600' : 
                      'text-gray-600'
                    }>
                      {vibe.coef_preference.toFixed(2)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VibeTable; 