import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

const VibeCoefficientsChart = ({ vibeScores }) => {
  // Remove the displayCoefficient state since we'll show both at once
  
  // Prepare data with processed labels for the chart
  const chartData = vibeScores.slice(0, 15).map(item => ({
    ...item,
    // Negate the coef_modelID value (* -1)
    coef_modelID: item.coef_modelID * -1,
    // Create a simpler version for the axis (remove markdown symbols)
    simplifiedVibe: item.vibe.replace(/\*\*/g, '').replace(/\*/g, '')
  }));
  
  // Calculate dynamic height based on number of vibes
  const heightPerVibe = 30; // Height allocated per vibe
  const chartHeight = Math.max(400, chartData.length * heightPerVibe);
  
  const renderTooltip = (active, payload, label) => {
    if (active && payload && payload.length) {
      // Find the original vibe from the data using the label
      const item = chartData.find(d => d.simplifiedVibe === label);
      const originalVibe = item ? item.vibe : label;
      const coefficient = payload[0].value;
      const coefficientType = payload[0].dataKey === "coef_modelID" ? "Model ID" : "Preference";
      
      return (
        <div className="bg-white p-2 border border-gray-300 shadow-md rounded max-w-md">
          <div className="markdown-content prose prose-sm max-w-none mb-1">
            <ReactMarkdown 
              remarkPlugins={[remarkMath]} 
              rehypePlugins={[rehypeKatex]}
            >
              {originalVibe}
            </ReactMarkdown>
          </div>
          <div className="text-sm">
            <div>
              <span className="font-semibold">{coefficientType} Coefficient: </span>
              <span>{coefficient.toFixed(2)}</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  
  return (
    <div className="mb-8">
      {/* <h2 className="text-xl font-bold mb-4">Vibe Coefficients Comparison</h2> */}
      <h3 className="text-md mb-4">Mouse over the chart to see the coefficients for each vibe. A higher Model ID coefficient indicates that model 1 better aligns with the "high" description. A higher preference coefficient indicates that responses that align with the "high" description are more preferred.</h3>
      <div className="flex flex-col md:flex-row gap-4">
        {/* Model ID Coefficients Chart */}
        <div className="bg-white p-4 rounded shadow flex-1">
          <h3 className="text-center font-semibold mb-2">Model ID Coefficients</h3>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <BarChart
              data={chartData}
              layout="vertical"
              margin={{ top: 10, right: 30, left: 20, bottom: 10 }}
              barCategoryGap={2}
            >
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis type="number" />
              <YAxis 
                type="category" 
                dataKey="simplifiedVibe"
                tick={false}
                axisLine={false}
              />
              <Tooltip content={({ active, payload, label }) => renderTooltip(active, payload, label)} />
              <Bar 
                dataKey="coef_modelID" 
                name="Model ID Coefficient" 
                fill="#8884d8" 
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        
        {/* Preference Coefficients Chart */}
        <div className="bg-white p-4 rounded shadow flex-1">
          <h3 className="text-center font-semibold mb-2">Preference Coefficients</h3>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <BarChart
              data={chartData}
              layout="vertical"
              margin={{ top: 10, right: 30, left: 20, bottom: 10 }}
              barCategoryGap={2}
            >
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis type="number" />
              <YAxis 
                type="category" 
                dataKey="simplifiedVibe"
                tick={false}
                axisLine={false}
              />
              <Tooltip content={({ active, payload, label }) => renderTooltip(active, payload, label)} />
              <Bar 
                dataKey="coef_preference" 
                name="Preference Coefficient" 
                fill="#82ca9d" 
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default VibeCoefficientsChart; 